import * as React from "react";
import Layout from "../../components/layout";
import HSCResults2022Content from "../../components/education/HSCResults2022Content";
import heroImage from "../../assets/education/angela-sassine-final.jpg";
import BannerImage from "../../components/master/BannerImage";

const HSCResults2022Page = () => {
  return (
    <Layout>
      <BannerImage imagePath={heroImage} altText="HSC Results 2022" />
        <HSCResults2022Content />
    </Layout>
  );
};

export default HSCResults2022Page;